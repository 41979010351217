import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { observer } from "mobx-react";
import AccessInsightsSVG from "media/access_insights.svg";
import GenerativeAISVG from "media/activity_reports.svg";
import AnomalyDetectSVG from "media/anomaly_detection.svg";
import CollaborativeAccessSVG from "media/collaborative-access.svg";
import ComprehensiveActivityLogsSVG from "media/comprehensive-activity-logs.svg";
import HeroTitleSVG from "media/hero-title.svg";
import HistoricalInsightsSVG from "media/historical-insights.svg";
import JustintimeAccessSVG from "media/justintime-access.svg";
import DevOpsSampleImgPNG from "media/sample-img.png";
import SharedRunbooksSVG from "media/shared-runbooks.svg";
import UIScreenshot01PNG from "media/ui-screenshot-01.png";
import UnifiedActionTrackingSVG from "media/unified-action-tracking.svg";
import { ContainerGrid, VerticalSlider } from "components/Container";
import DocumentTitle from "components/DocumentTitle";
import Notifications from "stores/Notifications";
import Store from "stores/Store";
var Sections = [
    {
        type: "grid",
        title: "Achieve Operational Transparency",
        description: "Gain complete visibility into infrastructure activities, enabling smarter decision-making and enhanced operational integrity. With Generative AI, uncover actionable insights to optimize workflows and build trust in your systems.",
        items: [
            {
                title: "Generative AI Activity Reports",
                imageSource: GenerativeAISVG,
                text: [
                    "Simplify complex technical details into clear, digestible summaries that non-technical stakeholders can understand by utilizing Gen AI to create detailed reports on engineers' activities across hosts effortlessly.",
                    "Assess team efficiency, track the frequency of trusted versus insecure commands, and identify patterns that impact operational reliability.",
                    "Centralized dashboards to monitor engineers' actions in real-time across environments.",
                ],
            },
            {
                title: "Role-Based Access Insights",
                imageSource: AccessInsightsSVG,
                text: [
                    "Monitor access permissions and actions tied to specific roles or individuals.",
                    "Ensure accountability by identifying and auditing misuse of privileged access.",
                    "Gain a clear understanding of access patterns to strengthen security and reduce vulnerabilities.",
                ],
            },
            {
                title: "Risk and Anomaly Detection",
                imageSource: AnomalyDetectSVG,
                text: [
                    "Track and log engineers' activities across bare metal, virtualized servers, and hybrid cloud environments.",
                    "Detect and highlight unusual or potentially insecure commands in real-time, minimizing risks to infrastructure.",
                    "Leverage AI-driven alerts to address deviations from standard procedures and mitigate potential threats proactively.",
                ],
            },
        ],
    },
    {
        type: "vertical-slider",
        title: "Instant Value from Day One",
        description: "Integrate a solution that delivers immediate results effortlessly into your operations. This platform empowers your team from day one with minimal setup and maximum transparency while maintaining budget clarity and achieving high-impact results immediately.",
        items: [
            {
                title: "SaaS Solution",
                imageSource: UIScreenshot01PNG,
                text: [
                    "Leverage a cloud-based platform that eliminates complex installations.",
                    "Access the system anytime, anywhere, with only a web browser required.",
                    "Reduce maintenance overhead with expert-managed infrastructure.",
                ],
            },
            {
                title: "Easy and Automated Setup (Zero-Config)",
                imageSource: UIScreenshot01PNG,
                text: [
                    "Start using the platform instantly with no configuration required.",
                    "Automate initial setups, ensuring you can focus on value rather than technical hurdles.",
                    "Enjoy seamless integration with your existing tools and systems.",
                ],
            },
            {
                title: "Pay-As-You-Go Pricing",
                imageSource: UIScreenshot01PNG,
                text: [
                    "Gain financial transparency with pricing based on usage, with no hidden costs.",
                    "Scale your investment according to your current needs without overcommitting.",
                    "Evaluate the platform's value with minimal financial risk.",
                ],
            },
            {
                title: "Predefined Workflows",
                imageSource: UIScreenshot01PNG,
                text: [
                    "Utilize ready-to-use workflows tailored for common use cases, reducing time to value.",
                    "Adapt workflows easily to fit your specific operational needs.",
                    "Empower your team with intuitive processes that require no learning curve.",
                    "Enhance efficiency with tools designed to support cross-functional teams.",
                ],
            },
        ],
    },
    {
        type: "grid",
        title: "Secure Access Simplified",
        description: "Empower your teams with secure, efficient, and accountable server access tailored to their needs.",
        items: [
            {
                title: "Just-in-Time Access",
                imageSource: JustintimeAccessSVG,
                text: [
                    "Enable temporary, on-demand access to servers based on specific tasks or roles.",
                    "Minimize the risks associated with persistent credentials by implementing time-limited access controls.",
                    "Simplify workflows with automated approvals and immediate access revocation after task completion.",
                ],
            },
            {
                title: "Collaborative access",
                imageSource: CollaborativeAccessSVG,
                text: [
                    "Shared sessions facilitate real-time collaboration by allowing multiple users to view and interact within a single terminal session.",
                    "Enhance oversight by enabling team leads or managers to monitor active sessions for guidance and accountability.",
                    "Record and replay shared sessions for training purposes and comprehensive audit trails.",
                ],
            },
            {
                title: "Shared runbooks",
                imageSource: SharedRunbooksSVG,
                text: [
                    "Allow server owners to review and approve specific scripts or commands before execution.",
                    "Prevent unauthorized or potentially harmful actions by adding an approval layer to critical operations.",
                    "Maintain a detailed log of approved and rejected actions for future reference and accountability.",
                ],
            },
        ],
    },
    {
        type: "grid",
        title: "Evidence Collection Automated",
        description: "Ensure readiness for audits and compliance reviews with precise, organized evidence powered by Gen AI. Build trust and accountability by maintaining verifiable activities.",
        items: [
            {
                title: "Comprehensive Activity Logs",
                imageSource: ComprehensiveActivityLogsSVG,
                text: [
                    "Maintain a detailed, immutable record of engineers' actions across servers, applications, and systems.",
                    "Capture the who, what, when, and where of every action to ensure accountability and transparency.",
                    "Facilitate efficient review processes with structured, searchable data.",
                ],
            },
            {
                title: "Unified Action Tracking",
                imageSource: UnifiedActionTrackingSVG,
                text: [
                    "Record all engineering activities in a unified timeline to provide a complete operational overview.",
                    "Trace the origin and impact of changes, ensuring a clear understanding of every modification.",
                ],
            },
            {
                title: "Historical Insights and Trends",
                imageSource: HistoricalInsightsSVG,
                text: [
                    "Analyze historical data to identify recurring issues or trends in engineering activities.",
                    "Support continuous improvement by understanding the impact of past decisions on current operations.",
                ],
            },
        ],
    },
    {
        type: "vertical-slider",
        title: "Happy DevOps: Keep Engineers in Their Flow",
        description: "Our product supports engineers by seamlessly integrating into their existing routines while delivering measurable value to the organization. With no mandatory new tools or workflows to learn, it enhances day-to-day operations without disruption. This ensures smooth adoption and ongoing efficiency for teams and leaders alike.",
        items: [
            {
                title: "Seamless Tool Compatibility",
                imageSource: DevOpsSampleImgPNG,
                text: [
                    "Works natively with Linux tooling, including terminals, scripts, and eventually almost everything that works on top of SSH, allowing engineers to continue using their trusted environments as is.",
                    "Provides out-of-the-box integration with industry-leading automation tools like Jenkins, Ansible, Terraform, etc.",
                ],
            },
            {
                title: "Preserve Proven Workflows",
                imageSource: DevOpsSampleImgPNG,
                text: [
                    "Supports previously approved companywide tools and standards, ensuring alignment with organizational policies and reducing disruptions.",
                    "Encourages adoption by maintaining familiar workflows, minimizing resistance and preventing bypass attempts.",
                ],
            },
            {
                title: "Enhanced Security and Control",
                imageSource: DevOpsSampleImgPNG,
                text: [
                    "Delivers advanced monitoring, auditing, and control features without requiring exclusive or privileged access.",
                    "Strengthens security while maintaining the flexibility engineers need for efficient operations.",
                ],
            },
            {
                title: "Effortless Server Onboarding with Cloud-Init Integration",
                imageSource: DevOpsSampleImgPNG,
                text: [
                    "Simplifies the onboarding of new servers by integrating seamlessly with cloud-init. This eliminates the need for complex configurations and enables teams to focus on operations instead of setup.",
                ],
            },
            {
                title: "Non-Disruptive Advanced Features",
                imageSource: DevOpsSampleImgPNG,
                text: [
                    "Unlocks detailed reporting, session recording, and secure web access, all without disrupting established workflows.",
                    "Access advanced functionalities with minimal learning curve or workflow changes.",
                ],
            },
        ],
    },
];
var Section = function (_a) {
    var section = _a.section;
    if (section.type === "grid") {
        return _jsx(ContainerGrid, { title: section.title, subtitle: section.description, items: section.items });
    }
    return _jsx(VerticalSlider, { title: section.title, subtitle: section.description, items: section.items });
};
export default observer(function () {
    var notifications = useState(Notifications)[0];
    var tmpError = function () {
        return notifications.error("Oops, something went wrong. Our best engineers is already working on fixing this.");
    };
    return (_jsxs(_Fragment, { children: [_jsx(DocumentTitle, { title: process.env.SLOGAN, noSuffix: true }), _jsx("div", { className: "main-hero-container", children: _jsxs("div", { className: "hero-title", children: [_jsx("img", { src: HeroTitleSVG, alt: "hero-title" }), _jsxs("div", { className: "hero-actions", children: [_jsx("button", { type: "button", className: "btn-transparent-large", onClick: tmpError, children: _jsx("span", { children: "Watch Demo" }) }), _jsx("button", { type: "button", className: "btn-primary-large", onClick: Store.ContactUsModal.showModal, children: _jsx("span", { children: "Contact Us" }) })] })] }) }), Sections.map(function (section) { return (_jsx(Section, { section: section }, section.title)); })] }));
});
